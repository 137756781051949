import React from 'react';
import './forgotpassword.scss';
import Logo from '../../assets/images/logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import { useHistory } from 'react-router';
import { emailIsValid } from '../../utils';
import { UserService } from '../../api';

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = React.useState('');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [error, setError] = React.useState('');
    const[isLoading, setIsLoading] = React.useState(false);

    const handleEmailChange = () => {
        setIsSuccess(false);
        setError('');
    };

    const navigateToLogin = () => {
        history.push('login/');
    };

    const keyPressNavigate = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            navigateToLogin();
        }
    };
    const keyPressEmailChange = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleEmailChange();
        }
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        UserService.forgotPassword(email).then((_response)=>{
            setIsSuccess(true);
            setError('');
        }).catch((_error)=>{
            setIsSuccess(false);
            setError("Something went wrong. Please Try Again");
        }).finally(()=> setIsLoading(false));
        
        
    };

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                {!isSuccess ? (
                    <>
                        <Typography variant="title" weight={500} color="tertiary">
                            FORGOT PASSWORD
                        </Typography>
                        <Typography variant="body">
                            Enter the email associated with your account and {`we'll`} send an email with instructions to
                            reset your password.
                        </Typography>
                        {error.length > 0 && (
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        )}
                        <InputField
                            onChange={(_name,value) => setEmail(value)}
                            name="email"
                            variant="outline"
                            outlineLabel="Email Address"
                            required={true}
                            value={email}
                            error={email.length> 0 && !emailIsValid(email)}
                        />
                        <div className="action-container">
                            <Typography variant="caption">
                                <span
                                    tabIndex={0}
                                    onClick={navigateToLogin}
                                    onKeyPress={keyPressNavigate}
                                    className="text-action"
                                >
                                    Back to login
                                </span>
                            </Typography>
                            <Button color="primary" loading={isLoading} onClick={handleResetPassword} disabled={!emailIsValid(email)}>
                                Send Instructions
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography variant="title" color="tertiary" textAlign="center">
                            We have sent a password recover instruction to your email.
                        </Typography>
                        <Typography variant="body" textAlign="center">
                            Did not receive the email? Check your spam filter, or&nbsp;
                            <strong
                                onClick={handleEmailChange}
                                onKeyPress={keyPressEmailChange}
                                className="text-action"
                                tabIndex={0}
                            >
                                try another email address
                            </strong>
                        </Typography>
                        <Button color="primary" onClick={navigateToLogin} className="mx-auto">
                            Back to Login
                        </Button>
                    </>
                )}
            </section>
        </div>
    );
};

export default ForgotPassword;
