import React from "react";
import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import Request, { CardType, RequestStatus } from "../../models/Request";
import { DataLoadingContext } from "../../components/DataLoading";
import { CardService } from "../../api";
import { getColorForStatus, getStatusFromEnum, getTypeFromEnum } from "../../utils";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";
import { ReactComponent as AcceptIcon} from '../../assets/icons/check.svg';
import { ReactComponent as DeclineIcon} from '../../assets/icons/x.svg';
import { ReactComponent as AssignIcon} from '../../assets/icons/nfccard.svg';
import IconButton from "../../components/Core/IconButton";
import { AlertDialogActionType, AlertDialogContext } from "../../components/Core/AlertDialog/state";
import { AuthContext } from "../Authentication/state";
import { UserRole } from "../../models/User";
import Typography from "../../components/Core/Typography";
import Button from "../../components/Core/Button";
import Modal from "../../components/Core/Modal";
import InputField from "../../components/Core/InputField";
import TokenModal from "./tokenModal";
import { Token } from "../../models/Invitation";



interface PageState {
    skip: number;
    take: number;
}
const initialDataState: PageState = { skip: 0, take: 8};

const RequestPage: React.FC = () =>{
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [request, setRequest] = React.useState<Request[]>([]);
    const [filteredData, setFilteredData] = React.useState<Request[]>([]);
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const[modalOpen, setModalOpen] = React.useState(false);
    const[tokenModalOpen, setTokenModalOpen] = React.useState(false);
    const [requestId, setRequestId] = React.useState('');
    const[tokens, setTokens] = React.useState<Token[]>([]);
    const[error, setError] = React.useState(false);
    const[quantity, setQuantity] = React.useState<number | undefined>();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;
    const{user} = React.useContext(AuthContext).state;
    const[isLoading, setIsLoading] = React.useState(false);

    React.useEffect(()=>{
        getData();
    }, [])

    React.useEffect(() => {
        if (filter) {
            setFilteredData(filterBy(request, filter));
        } else {
            setFilteredData(request);
        }
    }, [request]);

    const getData = () =>{
        toggleLoading(true);
        const request = (user?.role == UserRole.Admin) ? CardService.getAllRequests() : CardService.getRequestsForUser(user?.id); 
        request.then((response)=>{
            setRequest(response);
        }).finally(()=> toggleLoading(false));
    };

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const handleFilter = (event: GridFilterChangeEvent) => {
        setFilteredData(filterBy(request, event.filter));
        setFilter(event.filter);
    };

    const GridActions = (props: GridCellProps) => {
        const requestCell = props.dataItem as Request;
        return (
            <>
                {requestCell.status == RequestStatus.PENDING &&
                    <td className="grid-actions">
                        <IconButton Icon={AcceptIcon} onClick={()=>handleStatusChange(RequestStatus.ACCEPTED, requestCell.id)} tooltip="Accept" color="success"/>
                        <IconButton Icon={DeclineIcon} onClick={ ()=>handleStatusChange(RequestStatus.DECLINED, requestCell.id)} tooltip="Decline" color ='error'/>
                    </td>
                }
                {requestCell.status == RequestStatus.ACCEPTED &&
                    <td className="grid-actions">
                        <IconButton Icon={AssignIcon} onClick={ ()=>handleAssign(requestCell.id)} tooltip="Assign Tokens" color ='info'/>
                    </td> }
            </>
        );
    };

    const StatusCell = (props: any) => {
        const { dataItem } = props;
        const status = getStatusFromEnum(dataItem.status);
        return (
            <td>
                <Typography variant = "body" color = {getColorForStatus(dataItem.status)}>{status}</Typography>
            </td>
        );
    }

    const TypeCell = (props: any) => {
        const { dataItem } = props;
        const status = getTypeFromEnum(dataItem.cardType);
        return (
            <td>
                <Typography variant = "body">{status}</Typography>
            </td>
        );
    }


    const DateCell = (props: any) => {
        const { dataItem } = props;
        const dateValue = dataItem.dateCreated.split('T')[0];;
        return (
            <td>
                <p>{dateValue}</p>
            </td>
        );
    }

    const handleStatusChange = (status: RequestStatus, id: string) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Request New Card?',
                description: `Are you sure you want to ${status == RequestStatus.ACCEPTED? 'accept' : 'decline'} the request?`,
                handleConfirm: () => changeCardStatus(status, id)
            },
        });
    }
    const changeCardStatus = ( status: RequestStatus, id?:string) => {
        CardService.changeCardStatus(status, id)
        .then((response) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: response,
                        status: 'info',
                        autoClose: true,
                    },
                });
                getData();
        })
        .catch((e) => {            
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        })
    }

    
    const handleQuantityChange = (_name: string, value: string) => {
        setQuantity(parseInt(value));
    }

    const handleSubmitRequest = () =>{
        setIsLoading(true);
        setError(false);
        if(quantity == undefined ||isNaN(quantity) || quantity <= 0){
            setError(true);
            setIsLoading(false);
        }
        else{
            handleRequest(quantity, CardType.NEW, user?.id);
        }
    }

    const handleCardRequest = (userId?: string) => {
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Request New Card?',
                description: `Are you sure you want to request new card?`,
                handleConfirm: () => handleRequest(1, CardType.EXISTING, userId),
            },
        });
    }

    const handleRequest = (quantity: number, cardType: CardType, userId?: string,) =>{
        CardService.requestCard(quantity,cardType, userId)
            .then((res) => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: res,
                            status: 'info',
                            autoClose: true,
                        },
                    });
                    getData();
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            }).finally(()=> {setModalOpen(false);setIsLoading(false); setQuantity(undefined)});
    }

    const handleAssign = (id: string) => {
            setRequestId(id);
            CardService.getTokensForRequest(id).then((resp) => setTokens(resp));
            setTokenModalOpen(true);
    }

    return(
        <div className="request">
            <div className = "request-list">
                <TokenModal
                 requestId={requestId} 
                 tokens= {tokens} 
                 isOpen = {tokenModalOpen}
                 onSuccess ={()=>{getData(); setTokens([]);setTokenModalOpen(false);}  } 
                 onCancel={()=> {setTokens([]);setTokenModalOpen(false);}  } />
                <Grid
                    style = {{height: '100%'}}
                    data={orderBy(filteredData.slice(page.skip, page.take + page.skip), sort)}
                    sort = {sort}
                    sortable = {true}
                    onSortChange = {(e: GridSortChangeEvent) =>{
                        setSort(e.sort);
                    }}
                    skip = {page.skip}
                    take = {page.take}
                    total = {filteredData.length}
                    pageable = {true}
                    onPageChange = {pageChange}
                    filterable = {true}
                    filter = {filter}
                    onFilterChange = {handleFilter}

                >
                    <GridToolbar>
                        <Button onClick = {()=>handleCardRequest(user?.id)}>Request your card</Button>
                        <Button onClick = {()=>setModalOpen(true)}>Request multiple cards</Button>
                    </GridToolbar>
                    {(user?.role == UserRole.Admin)  && <GridColumn field="email" title="Email Address" />}
                    <GridColumn field='quantity' title = "Quantity"/>
                    <GridColumn field="status" title="Status" cell={StatusCell} filterable = {false}/>
                    <GridColumn field="cardType" title="Card Type" cell={TypeCell} filterable = {false}/>
                    <GridColumn field = "dateCreated" title = "Date Requested" cell = {DateCell}/>
                    {(user?.role == UserRole.Admin)  && <GridColumn cell={GridActions} filterable={false} width = '100px'/>}
                </Grid>           
            </div>
            <Modal isOpen={modalOpen} close={() => { setError(false); setModalOpen(false), setQuantity(undefined) }} size='medium' title="Request Multiple Cards">
                <div>
                    <div>
                        {error && <Typography variant='body' color='error' textAlign='center'>{error}</Typography>}
                        <Typography variant="body">
                            How many cards do you want to request <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleQuantityChange}
                            name="quantity"
                            type="number"
                            value = {quantity}
                            error = {error && (quantity== undefined || quantity<=0)}
                            errorText="Invalid Quantity"
                        />
                    </div>
                </div>
                <div className="action-container" style = {{justifyContent: 'flex-end'}}>
            <Button
                color="primary"
                loading={isLoading}
                onClick={handleSubmitRequest}
            >
                Request
            </Button>
        </div>
            </Modal>
        </div>
    )
}

export default RequestPage;