import getCountries from "./getCountries";
import getStates from "./getStates";
import useDebounse from "./useDebounce";
import useWindowDimensions from "./useWindowDimensions";
import exportToCSV from "./csvExport";
import { CardType, RequestStatus } from "../models/Request";

const emailIsValid = (email: unknown): boolean => {
    if (typeof email !== 'string') {
        return false;
    } else {
        const exp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
        return exp.test(email);
    }
};

const passwordIsValid = (password: unknown): boolean => {
    if (typeof password !== 'string') {
        return false;
    } else {
        const exp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        return exp.test(password);
    }
};

const phoneNumberIsValid = (value: unknown): boolean => {
    if (typeof value !== 'string') {
        return false;
    } else {
        const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (regex.test(value) && value.length === 10) {
            return true;
        } else {
            return false;
        }
    }
};

const passwordAreEqual = (password: unknown, confirmPassword: unknown): boolean => {
    if (typeof password !== 'string' || typeof confirmPassword !== 'string') {
        return false;
    } else {
        return confirmPassword === password;
    }
};

const zipCodeIsValid = (zip: unknown): boolean => {
    if (typeof zip !== 'string') {
        return false;
    } else {
        return zip.length === 5;
    }
};

const ageIsValid = (dob: unknown): boolean => {
    if (typeof dob !== 'string') {
        return false;
    } else {
        const parts = dob.split('-');
        if (parts.length < 3) {
            return false;
        }
        const dtDOB = new Date(parts[1] + '/' + parts[2] + '/' + parts[0]);
        const dtCurrent = new Date();
        if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 18) {
            return false;
        }
        if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 18) {
            //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
            if (dtCurrent.getMonth() < dtDOB.getMonth()) {
                return false;
            }
            if (dtCurrent.getMonth() == dtDOB.getMonth()) {
                //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
                if (dtCurrent.getDate() < dtDOB.getDate()) {
                    return false;
                }
            }
        }
        return true;
    }
};

const urlIsValid = (url: unknown): boolean => {
    if (typeof url !== 'string') {
        return false;
    } else {
        const regex = new RegExp(
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        );
        if (regex.test(url)) {
            return true;
        } else {
            return false;
        }
    }
};

const formatPhoneNumber = (phoneNumber: unknown): string => {
    if (typeof phoneNumber !== 'string') {
        return 'Invalid number';
    } else {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return 'Invalid number';
    }
};

const formatLocation = (
    addressline1: string,
    addressline2 = '',
    city: string,
    state: string,
    country: string,
    zip: string,
) => {
    return [addressline1, addressline2, city, state, country, zip].join(', ').replace(/(, ){2,}/g, ', ');
};

const formatDate = (date: string | Date):string => {
    return new Date(date).toLocaleDateString('en-US');
}

const FormatDateInputValue = (date: string | Date | number | null | undefined):string=> {
    const local = (date && date.toString().length>0) ? new Date(date): new Date();
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON().slice(0,10);
}
const setCookie=(cname: string, expireAfterMonths: number, value: string, path?:string) => {
    const expiryMonth = new Date(new Date().getMonth()+expireAfterMonths).toUTCString();
    document.cookie = `${cname}=${value}; expires=${expiryMonth}; path=${path}`;
}

const getCookie=(cname: string):string=> {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";

}

const getUserRoles = (): { value: string; label: string }[] => {
    return [
        {
            label: 'Admin',
            value: 'Admin',
        },
        {
            label: 'Manager',
            value: 'Manager',
        },
        {
            label: 'Synner',
            value: 'Synner',
        },        
        {
            label: 'Trainee',
            value: 'Trainee',
        },
    ];
};

const endDateIsValid = (startDate: unknown, endDate: unknown): boolean => {
    if (typeof startDate !== 'string' || typeof endDate !== 'string') {
        return false;
    } else {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);

        return newEndDate >= newStartDate;
    }
};

const amountIsValid = (amount: unknown): boolean => {
    if (typeof amount !== 'string' && typeof amount !== 'number') {
        return false;
    } else {
        const regex = new RegExp(/^\d+(?:[.,]\d+)*$/);
        if (regex.test(amount.toString())) {
            return true;
        } else {
            return false;
        }
    }
};

const getStatusFromEnum = (status: any) =>{
    for (const name in RequestStatus) {
        if (RequestStatus[name] == status) {
            return name;
        }
    }
}
const getTypeFromEnum = (status: any) =>{
    for (const name in CardType) {
        if (CardType[name] == status) {
            return name;
        }
    }
}

const getColorForStatus = (status?: RequestStatus) => {
    switch(status){
        case RequestStatus.ACCEPTED:
            return 'success';
        case RequestStatus.DECLINED:
            return 'error';
        default:
            return 'info';
    }
};

export {
    emailIsValid,
    passwordAreEqual,
    phoneNumberIsValid,
    zipCodeIsValid,
    passwordIsValid,
    ageIsValid,
    urlIsValid,
    getStates,
    getCountries,
    endDateIsValid,
    amountIsValid,
    formatPhoneNumber,
    getUserRoles,
    formatLocation,
    formatDate,
    getCookie,
    setCookie,
    useDebounse,
    useWindowDimensions,
    exportToCSV,
    FormatDateInputValue,
    getStatusFromEnum,
    getTypeFromEnum,
    getColorForStatus
};
