import React from 'react';
import Typography from '../../components/Core/Typography';
import Logo from '../../assets/images/logo.png';
import { Link, useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
    const history = useHistory();

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <Typography variant='heading' weight={800} color="tertiary" textAlign='center'>404</Typography>
                <Typography variant="heading" weight={500} color="secondary" textAlign='center'>
                    PAGE NOT FOUND
                </Typography>
                <Typography variant="subtitle" textAlign='center'>
                    The page you are looking for doesnot exist. Let us guide you back!
                </Typography>
                <br/>
                <Typography variant="subtitle" textAlign="center" color="info">
                    <span
                        tabIndex={0}
                        onClick={() => history.goBack()}
                        onKeyPress={(e: React.KeyboardEvent<HTMLSpanElement>) => e.key === 'Enter' && history.goBack()}
                        className="text-action"
                    >
                        Back
                    </span>&nbsp;&nbsp;&nbsp;
                    <Link to="/login"><span
                        className="text-action"
                    >
                        Login
                    </span></Link>
                </Typography>
            </section>
        </div>
    );
};

export default NotFound;
