export default interface Invitation {
    id: string;
    email: string;
    status: InvitationStatus
}

export enum InvitationStatus {
    REGISTERED,
    PENDING,
    TOKEN_GENERATED,
    TOKEN_ASSIGNED
}

export interface Token {
    token: string,
    status: InvitationStatus
}