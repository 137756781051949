import React from 'react';
import './resetpassword.scss';
import Logo from '../../assets/images/logo.png';
import Typography from '../../components/Core/Typography';
import Button from '../../components/Core/Button';
import InputField from '../../components/Core/InputField';
import { useHistory, useParams } from 'react-router';
import { passwordAreEqual, passwordIsValid } from '../../utils';
import { UserService } from '../../api';

type resetPassword = {
    password: string,
    confirmPassword: string
}
const ResetPassword: React.FC = () => {
    const history = useHistory();
    const [resetPassword, setResetPassword] = React.useState<resetPassword>({password: '', confirmPassword: ''});
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [error, setError] = React.useState('');
    const { token, email } = useParams<{ token: string, email:string }>();
    const[isLoading, setIsLoading] = React.useState(false);
    const[showError, setShowError] = React.useState(false)

    const handleChange=(name:string, value:string)=> {
        setResetPassword({...resetPassword, [name]: value});
    } 
    const navigateToLogin = () => {
        history.push('/login');
    };

    const handleResetPassword = () => {
        setIsLoading(true);
        setShowError(false);
        if(!passwordIsValid(resetPassword.password) || !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)){
            setShowError(true);
            setIsLoading(false);
        }
        else{
            UserService.resetForgottenPassword(email,token,resetPassword.password).then((_response)=>{
                setIsSuccess(true);
                setError('');
            }).catch((_error)=>{
                setIsSuccess(false);
                setError("Something went wrong. Please Try Again");
            }).finally(()=> setIsLoading(false));
        }
    };

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                {!isSuccess ? (
                    <>
                        <Typography variant="title" weight={500} color="tertiary">
                            CREATE A NEW PASSWORD
                        </Typography>
                        {error.length > 0 && (
                            <Typography variant="body" color="error">
                                {error}
                            </Typography>
                        )}
                         <Typography variant="body">
                            Password <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleChange}
                            name="password"
                            type="password"
                            required={true}
                            value={resetPassword.password}
                            error={showError && !passwordIsValid(resetPassword.password)}
                            errorText="Invalid Password"
                        />
                         <Typography variant="body">
                           Confirm-Password <span className="color-error">*</span>
                        </Typography>                        
                        <InputField
                            onChange={handleChange}
                            name="confirmPassword"
                            type="password"
                            required={true}
                            value={resetPassword.confirmPassword}
                            error={showError && !passwordAreEqual(resetPassword.password, resetPassword.confirmPassword)}
                            errorText="passwords do not match"
                        />
                        <div className="action-container">
                            <Button loading = {isLoading} color="primary" className='mx-auto' onClick={handleResetPassword}>
                                Reset Password
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography variant="title" color="tertiary" textAlign="center">
                            Your password was successfully reset!
                        </Typography>                        
                        <Button color="primary" onClick={navigateToLogin} className="mx-auto">
                            Back to login
                        </Button>
                    </>
                )}
            </section>
        </div>
    );
};

export default ResetPassword;
