import { UserViewModel } from '../models/User';
import instance from './instance';

function getUserById(Id: string | undefined):Promise<UserViewModel> {
    return instance.get(`user?Id=${Id}`)
    .then((response) => {          
        return response.data;
    });
}

function updateUser(user: UserViewModel):Promise<string> {
    return instance.put('user',user)
    .then((response) => {                 
        return response.data;
    });
}
function createUser(email: string, token:string, password: string):Promise<string> {
    return instance.post('user/register',{ token: token, email: email, password: password})
    .then((response) => {                 
        return response.data;
    });
}

function forgotPassword(email:string): Promise<string>{
    return instance.get(`user/forgot-password?email=${email}`)
    .then((response) => {                 
        return response.data;
    });
}

function resetForgottenPassword(email: string, token:string, password: string):Promise<string> {
    console.log(email);
    return instance.post('user/reset-forgotten-password',{ token: token, email: email, password: password})
    .then((response) => {                 
        return response.data;
    });
}

export {getUserById, updateUser, createUser, forgotPassword, resetForgottenPassword};