import User from '../models/User';
import instance from './instance';

function loginWithToken():Promise<User> {
    return instance.get('user/token-login')
            .then((response) => {
                const user:User = response.data; 
                return user;
            });
    }

    function checkNFCStatus(token:string):Promise<boolean> {
        return instance.post(`getting-started?token=${token}`)
                .then((response) => {
                    return response.data;
                });
        }

        function getVCard(token:string):Promise<any> {
            return instance.get(`user/vcard?userId=${token}`)
                    .then((response) => {
                        return response.data;
                    });
            }

    function login(emailAddress: string, _password: string): Promise<User> {
        return instance
            .post('user/login', {
                email: emailAddress,
                password: _password,
            })
            .then((response) => {
                return (response.data);
            });
        // return new Promise((resolve)=>{
        //     resolve({firstName: "Test", lastName: "user", accessToken: 'test', role: UserRole.User, id: "test id", email: emailAddress, photo: "https://placeimg.com/295/295/any/tech?t=2"})
        // })
    }

export { loginWithToken, login, checkNFCStatus, getVCard };
