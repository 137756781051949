import axios, { AxiosResponse } from 'axios';


async function getImageBase64(url: string): Promise<string|null> {
  try {
    const response: AxiosResponse<ArrayBuffer> = await axios.get(url, {
      responseType: 'arraybuffer'
    });
    const imageArray: Uint8Array = new Uint8Array(response.data);
    const imageBuffer: Buffer = Buffer.from(imageArray.buffer);
    const base64Image: string = "data:image/jpeg;base64," + imageBuffer.toString('base64');
    return base64Image;

    } catch (error) {

      return null;

    }
}

export {getImageBase64}