import React from 'react';
import {useWindowDimensions} from '../../../utils';
import './menu.scss';

type Props = {
    elementRef: React.RefObject<HTMLElement>;
    open: boolean;
    close: () => void;
    width: number;
    children: React.ReactChild | React.ReactChild[];
    align?: 'left' | 'right';
};

const Menu: React.FC<Props> = ({ open, elementRef, width, children, align = 'left', close }) => {
    const menuRef = React.useRef<HTMLDivElement>(null);
    const [top, setTop] = React.useState(0);
    const [left, setLeft] = React.useState(0);

    const windowWidth = useWindowDimensions().width;

    React.useEffect(() => {
        if (open) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const clickListner = (e: any) => {
                if (!menuRef.current?.contains(e.target)) {
                    close();
                    document.removeEventListener('click', clickListner);
                }
            };
            document.addEventListener('click', clickListner);
            setPositionProperty();
        }
    }, [open]);

    React.useEffect(() => {
        setPositionProperty();
    }, [windowWidth]);

    const setPositionProperty = () => {
        const elementHeight = elementRef.current ? elementRef.current.offsetHeight : 0;
        const elementWidth = elementRef.current ? elementRef.current.offsetWidth : 0;
        const elementLeft = elementRef.current ? elementRef.current.offsetLeft : 0;
        const elementTop = elementRef.current ? elementRef.current.offsetTop : 0;
        setTop(elementHeight + elementTop);
        setLeft(align === 'left' ? elementLeft - elementWidth - 10 : elementLeft + elementWidth + 10);
    };

    return (
        <>
            {open && elementRef && (
                <div className={`menu-container align-${align}`} style={{ top: top, left: left, width: width }} ref={menuRef}>
                    {children}
                </div>
            )}
        </>
    );
};

export const MenuItem: React.FC<{ onClick: () => void; label: string }> = ({ label, onClick }) => {
    return (
        <div
            className="menu-item"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && onClick()}
        >
            <p>{label}</p>
        </div>
    );
};

export default Menu;
