export default interface User {
    id: string;
    firstName: string;
    lastName: string;    
    role: UserRole;
    accessToken: string;
    photo: string;
    email: string;
}

export enum UserRole {   
    Admin = 0,
    User = 1,
    Visitor = 2
}

export interface UserViewModel{
    id?: string;
    firstName: string;
    lastName: string;
    email:string;
    phoneNumber: string;
    company: string;
    title: string;
    address: string;
    zipCode: string;
    city: string;
    state: string;
    country: string;
    mobile: string;
    website: string;
    linkedin: string;
    photo: string;
    note: string;
}