import React from 'react';
import './profile.scss';
import { UserViewModel } from '../../models/User';
import ProfileDetail from './ProfileDetails';
import { ProfileDetailsForm } from './ProfileDetailsForm';
import { AuthContext } from '../Authentication/state';
import { UserService } from '../../api';

const Profile: React.FC = () => {
    const [edit, setEdit] = React.useState(false);
    const { user } = React.useContext(AuthContext).state;
    const[userProfile, setUserProfile] = React.useState<UserViewModel>();

    React.useEffect(()=>{
        UserService.getUserById(user?.id).then((resp)=> setUserProfile(resp));
    },[edit]);

    React.useEffect(()=>{
      if(userProfile?.address.length == 0){
        setEdit(true);
      }
    },[userProfile])

       return (
        <>
          {userProfile?
            <>
              {edit ? 
              <ProfileDetailsForm user = {userProfile} onEditOrCancel={()=> setEdit(false)}/>
              :
              <ProfileDetail user = {userProfile} onEdit={()=>setEdit(true)} />}
            </>: <></>
          }
        </>
    
       );
        

};

export default Profile;
