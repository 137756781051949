export default interface Request{
    id: string,
    userId: string,
    email: string,
    status: RequestStatus,
    quantity: number,
    cardType: CardType   
}

export enum RequestStatus{
    PENDING,
    ACCEPTED,
    DECLINED,
    ASSIGNED
}

export enum CardType {
    EXISTING, 
    NEW
}