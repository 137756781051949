import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

const instance = axios.create({
    baseURL:process.env.REACT_APP_SERVICE_BASE_URL,
});

instance.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
        const headers: AxiosRequestHeaders = config.headers as AxiosRequestHeaders;
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('login_token');
        return config;
    },
    function (err) {
        return Promise.reject(err);
    },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
instance.interceptors.response.use(
    (resp) => {
        if (resp.data?.statusCode >= 400 || resp.data.isError) {
            const errorMessage =
                resp?.data?.responseException ??
                'Something went wrong. Please try again later.';
            return Promise.reject(new Error(errorMessage));
        }
        return Promise.resolve({
            ...resp,
            data: resp.data.result !== undefined ? resp.data.result : resp.data,
        });
    },
    (error) => {
        const errorMessage =
            error?.response?.responseException ??
            'Something went wrong. Please try again later.';
        return Promise.reject(new Error(errorMessage));
    },
);

export default instance;
