import React, { useRef } from 'react';
import { FormatDateInputValue } from '../../../utils';
import './input.scss';

type Props = {
    onChange: (name: string, value: string) => void;
    onBlur?: (name: string, value: string) => void;
    onFocus?: (name: string, value: string) => void;
    type?: React.HTMLInputTypeAttribute;
    disabled?: boolean;
    name: string;
    outlineLabel?: string;
    className?: string;
    id?: string | undefined;
    error?: boolean;
    errorText?: string;
    required?: boolean;
    variant?: string;
    placeholder?: string;
    value?: string | number;
    step?:number;
    min?:number;
    max?: number;
    cypressId?: string;
};

const InputField: React.FC<Props> = ({
    onChange,
    onBlur,
    onFocus,
    type = 'text',
    disabled = false,
    name,
    id,
    outlineLabel = ' ',
    className,
    error,
    errorText,
    required,
    variant = 'standard',
    placeholder = ' ',
    value,
    step,
    min,
    max,
    cypressId
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e.target.name, e.target.value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if(onBlur)
        onBlur(e.target.name, e.target.value);
    };
    const handleFocus = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if(onFocus)
        onFocus(e.target.name, e.target.value);
    };
    return (
        <div className="input-field-container">
            <input
                type={type}
                className={`input-field ${className ? className : ''} ${error ? 'error' : ''}`}
                placeholder={variant !== 'outline' ? placeholder : ' '}
                onChange={handleChange}
                disabled={disabled}
                name={name}
                id={id}
                required={required}
                step={step}
                min={min}
                max={max}
                onBlur = {handleBlur}
                onFocus = {handleFocus}
                value={type === 'date' ? FormatDateInputValue(value) : value}
                ref={inputRef}
                onWheel={()=>{inputRef?.current?.blur()}}
                data-cy={cypressId}
            />
            {variant === 'outline' && (
                <label
                    htmlFor={name}
                    className="input-label-outline"
                    onClick={() => inputRef.current && inputRef.current.focus()}
                >
                    {required && '*'}
                    {outlineLabel}
                </label>
            )}
            {error && <p className="error-text" data-cy = "input-field-error">{errorText} </p>}
        </div>
    );
};

export default InputField;
