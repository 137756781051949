import React from 'react';
import Select, { SingleValue } from 'react-select';
import './select.scss';
export type Option = {
    label: string;
    value: string;
};

type Props = {
    onChange: (name: string, value: string) => void;
    name: string;
    value?: string | null;
    options: Array<Option>;
    placeholder: string;
    error?: boolean;
    errorText?: string;
    disabled?: boolean;
    cypressId?: string;
    isSearchable?: boolean;
};

const InputSelect: React.FC<Props> = ({
    name,
    value = '',
    options,
    cypressId,
    placeholder,
    onChange,
    error,
    errorText,
    disabled = false,
    isSearchable,
}) => {
    const [selected, setSelected] = React.useState<Option | undefined | null>();

    React.useEffect(() => {
        const newValue = options.find((element: Option) => element.value === value);
        setSelected(newValue);
    }, [value]);

    const handleChange = (option: SingleValue<Option>): void => {
        if (option) {
            onChange(name, option.value);
        }
    };
    return (
        <div className="select-container" data-cy={cypressId}>
            <Select
                onChange={handleChange}
                name={name}
                options={options}
                value={selected}
                placeholder={placeholder}
                classNamePrefix={error ? 'error-select' : 'input-select'}
                isDisabled={disabled}
                isSearchable={isSearchable}
            />
            {error && (
                <p className="error-text" data-cy="input-select-error">
                    {errorText}
                </p>
            )}
        </div>
    );
};

export default InputSelect;
