import React from 'react';
import IconButton from '../IconButton';
import Typography from '../Typography';
import './modal.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/x-circle.svg';

type Props = {
    isOpen: boolean;
    close: () => void;
    title: string;
    children: JSX.Element | JSX.Element[];
    size: 'small' | 'medium' | 'large';
};

const Modal: React.FC<Props> = ({ isOpen, title, children, size, close }) => {
    return (
        <>
            {isOpen && (
                <div className="modal-container" onClick={close}>
                    <div className={`modal-wrapper modal-wrapper--${size}`} onClick={(e) => e.stopPropagation()}>
                        <div className='header'>
                            <div className="modal-close">
                                <IconButton Icon={CloseIcon} onClick={close} tooltip="close modal"/>
                            </div>
                            <Typography variant="subtitle">
                                {title}
                            </Typography>
                        </div>
                        <div className="modal-content">{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export const ModalActionBar: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
    return <div className="action-bar">{children}</div>;
};

export default Modal;
