import React from "react";
import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import Invitation, { InvitationStatus } from "../../models/Invitation";
import { DataLoadingContext } from "../../components/DataLoading";
import { InviteService } from "../../api";
import Button from "../../components/Core/Button";
import Modal from "../../components/Core/Modal";
import Typography from "../../components/Core/Typography";
import InputField from "../../components/Core/InputField";
import { emailIsValid } from "../../utils";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";
import {ReactComponent as InviteIcon} from '../../assets/icons/mail.svg';
import IconButton from "../../components/Core/IconButton";
import { AlertDialogActionType, AlertDialogContext } from "../../components/Core/AlertDialog/state";


interface PageState {
    skip: number;
    take: number;
}
const initialDataState: PageState = { skip: 0, take: 8};

const Invite: React.FC = () =>{
    const {toggleLoading} = React.useContext(DataLoadingContext);
    const [invitation, setInvitation] = React.useState<Invitation[]>([]);
    const [filteredData, setFilteredData] = React.useState<Invitation[]>([]);
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    const [page, setPage] = React.useState<PageState>(initialDataState);
    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>();
    const [error, setError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    const[isLoading, setIsLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const alertDialogDispatch = React.useContext(AlertDialogContext).alertDialogDispatch;

    React.useEffect(()=>{
        getData();
    }, [])

    React.useEffect(() => {
        if (filter) {
            setFilteredData(filterBy(invitation, filter));
        } else {
            setFilteredData(invitation);
        }
    }, [invitation]);

    const getData = () =>{
        toggleLoading(true);
        InviteService.getAllInvitations().then((response)=>{
            setInvitation(response);
        }).finally(()=> toggleLoading(false));
    };

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    const handleFilter = (event: GridFilterChangeEvent) => {
        setFilteredData(filterBy(invitation, event.filter));
        setFilter(event.filter);
    };

    const handleReInvite = (email: string) =>{
        alertDialogDispatch({
            type: AlertDialogActionType.OPEN,
            payload: {
                title: 'Reinvite the user?',
                description: `Do you want to resend the invitation email?`,
                handleConfirm: () => sendInvitionMail(email)
            },
        });
    }

    const GridActions = (props: GridCellProps) => {
        const invitationCell = props.dataItem as Invitation;
        return (
            <td className="grid-actions">
                {invitationCell.status == InvitationStatus.PENDING &&
                    <IconButton Icon={InviteIcon} onClick={()=>{handleReInvite(invitationCell.email);}} tooltip='ReInvite'/>
                }
            </td>
        );
    };

    const StatusCell = (props: any) => {
        const { dataItem } = props;
        let status;
        for (const name in InvitationStatus) {
            if (InvitationStatus[name] === dataItem.status) {
                status = name;
            }
        }
        return (
            <td>
                <p>{status}</p>
            </td>
        );
    }

    const handleEmailChange = (_name: string, value: string) => {
        setEmail(value);
    }

    const handleInvite = () =>{
        setIsLoading(true);
        setError(false);
        if(!emailIsValid(email)){
            setError(true);
            setIsLoading(false)
        }
        else{
            sendInvitionMail(email);
            
        }       
    }

    const sendInvitionMail = (emailAddress :string) => {
        InviteService.inviteUser(emailAddress)
        .then((response) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: response,
                        status: 'info',
                        autoClose: true,
                    },
                });
                getData();
                setEmail('');
                setModalOpen(false);
        })
        .catch((e) => {            
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(()=> {setIsLoading(false);});
    }

    return(
        <div className="invitation">
            <div className = "invitation-list">
                <Grid
                    style = {{height: '100%'}}
                    data={orderBy(filteredData.slice(page.skip, page.take + page.skip), sort)}
                    sort = {sort}
                    sortable = {true}
                    onSortChange = {(e: GridSortChangeEvent) =>{
                        setSort(e.sort);
                    }}
                    skip = {page.skip}
                    take = {page.take}
                    total = {filteredData.length}
                    pageable = {true}
                    onPageChange = {pageChange}
                    filterable = {true}
                    filter = {filter}
                    onFilterChange = {handleFilter}
                >
                    <GridToolbar>
                    <Button onClick = {()=>setModalOpen(true)}>Invite</Button>
                    </GridToolbar>
                    <GridColumn field="email" title="Email Address" />
                    <GridColumn field="status" title="Status" cell={StatusCell} filterable = {false}/>
                    <GridColumn cell={GridActions} filterable={false} width = '100px'/>
                </Grid>           
            </div>
            <Modal isOpen={modalOpen} close={() => { setError(false); setModalOpen(false), setEmail('') }} size='large' title="Invite User">
                <div>
                    <div>
                        {error && <Typography variant='body' color='error' textAlign='center'>{error}</Typography>}
                        <Typography variant="body">
                            Email Address <span className="color-error">*</span>
                        </Typography>
                        <InputField
                            onChange={handleEmailChange}
                            name="email"
                            type="text"
                            value = {email}
                            error = {error && !emailIsValid(email)}
                            errorText="Invalid Email Address"
                        />
                    </div>
                </div>
                <div className="action-container" style = {{justifyContent: 'flex-end'}}>
            <Button
                color="primary"
                loading={isLoading}
                onClick={handleInvite}
            >
                Invite
            </Button>
        </div>
            </Modal>
        </div>
    )
}

export default Invite;