import React from "react";
import { UserViewModel } from "../../models/User";
import Typography from "../../components/Core/Typography";
import InputField from "../../components/Core/InputField";
import Button from "../../components/Core/Button";
import TextArea from "../../components/Core/TextArea";
import InputSelect from "../../components/Core/InputSelect";
import { emailIsValid, getStates, phoneNumberIsValid } from "../../utils";
import ProfilePhoto from '../../assets/images/default.png';
import { ImageService, UserService } from "../../api";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";
import Radio from "../../components/Core/Radio";

type Props = {
    user: UserViewModel,
    onEditOrCancel: () => void,
}
export const ProfileDetailsForm: React.FC<Props> = ({ user, onEditOrCancel }) => {
    const [profile, setProfile] = React.useState<UserViewModel>(user);
    const [showError, setShowError] = React.useState(false);
    const [pictureError, setPictureError] = React.useState(false);
    const states = getStates();
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [isLoading, setIsLoading] = React.useState(false);
    const [upload, setUpload] = React.useState(true);
    const [photoUrl, setPhotoUrl] = React.useState('');


    const handleImageChange = (event: any) => {
        setPictureError(false);
        const file = event.target.files[0];
        if (file) {
            if (file.type.split("/")[0] !== "image") {
                setPictureError(true);
                return
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setProfile({ ...profile, photo: reader.result as string })
            };
        }
        return;
    };


    const onEdit = (name: string, value: string) => {
        setProfile({ ...profile, [name]: value });
    };


    const onUpdate = () => {
        setShowError(false);
        setIsLoading(true);

        const isValid = profile.firstName.length > 0 &&
            profile.lastName.length > 0 &&
            emailIsValid(profile.email) &&
            phoneNumberIsValid(profile.phoneNumber) &&
            profile.address.length > 0 &&
            profile.city.length > 0 &&
            profile.state.length > 0 &&
            profile.zipCode.length > 0;


        if (isValid) {
            UserService.updateUser(profile)
                .then((response) => {
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: response,
                            status: 'info',
                            autoClose: true,
                        },
                    });
                    onEditOrCancel();
                })
                .catch((e) => {

                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: e.message,
                            status: 'error',
                            autoClose: true,
                        },
                    });
                }).finally(() => setIsLoading(false));

        }
        else {
            setIsLoading(false);
            setShowError(true);
        }

    }

    const onPhotoUrlChange = (_name: string, value: string) => {
        setPictureError(false);
        setPhotoUrl(value);
        ImageService.getImageBase64(value).then((response) => {
            if (response == null) {
                setProfile({ ...profile, photo: '' })
                setPictureError(true);
            }
            else {
                setProfile({ ...profile, photo: response })
            }
        })
            .catch((_e) => {
                setProfile({ ...profile, photo: '' })
                setPictureError(true);
            })
    };


    return (
        <div className='profile-edit paper'>
            <Typography variant="title" weight={500}>
                UPDATE PROFILE
            </Typography>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Choose a new photo
                    </Typography>
                    <div className="radio-photo">
                        <Radio onChange={() => setUpload(true)} checked={upload} label="Upload a photo"></Radio>
                        <Radio onChange={() => setUpload(false)} checked={!upload} label="Enter image url"></Radio>
                    </div>

                    <br />
                    {upload ? <>
                        <input type="file" id="fileInput" accept="image/*" onChange={handleImageChange} placeholder="Select" />
                        <p>{pictureError && "Please upload valid image file."}</p>
                    </> :
                        <>
                            <InputField
                                type="text"
                                value={photoUrl}
                                name="photoUrl"
                                error={pictureError && photoUrl.length > 0}
                                errorText="Enter valid Url"
                                onChange={onPhotoUrlChange}
                                placeholder="Enter image Url"
                            />

                        </>
                    }
                </div>
                <div className="image-container">
                    <img src={profile.photo.length > 0 ? profile.photo : ProfilePhoto} width="150" alt="Profile" />
                    {profile.photo.length > 0 && <div className="clear-container" ><p className="clear" onClick={() => setProfile({ ...profile, photo: '' })}>Delete</p></div>}
                </div>

            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        First Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.firstName}
                        name="firstName"
                        error={showError && profile.firstName.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Last Name <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.lastName}
                        name="lastName"
                        error={showError && profile.lastName.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Email Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.email}
                        name="email"
                        error={showError && !emailIsValid(profile.email)}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Phone Number <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="number"
                        value={profile.phoneNumber}
                        name="phoneNumber"
                        error={showError && !phoneNumberIsValid(profile.phoneNumber)}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Company <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.company}
                        name="company"
                        error={showError && profile.company.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Title <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.title}
                        name="title"
                        error={showError && profile.title.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Street Address <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.address}
                        name="address"
                        error={showError && profile.address.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        City <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.city}
                        name="city"
                        error={showError && profile.city.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        State <span className="color-error">*</span>
                    </Typography>
                    <InputSelect
                        options={states}
                        value={profile.state}
                        name="state"
                        placeholder="State"
                        error={showError && profile.state.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Zipcode <span className="color-error">*</span>
                    </Typography>
                    <InputField
                        type="number"
                        value={profile.zipCode}
                        name="zipCode"
                        error={showError && profile.zipCode.length == 0}
                        errorText="required"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Website
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.website}
                        name="website"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Linkedin
                    </Typography>
                    <InputField
                        type="text"
                        value={profile.linkedin}
                        name="linkedin"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="form-grid-half">
                <div>
                    <Typography variant="body">
                        Mobile
                    </Typography>
                    <InputField
                        type="number"
                        value={profile.mobile}
                        name="mobile"
                        onChange={onEdit}
                    />
                </div>
                <div>
                    <Typography variant="body">
                        Notes
                    </Typography>
                    <TextArea
                        value={profile.note}
                        name="note"
                        onChange={onEdit}
                    />
                </div>
            </div>
            <div className="action-container">
                {user.address.length > 0 &&
                    <Button
                        color="primary"
                        onClick={onEditOrCancel}
                    >
                        Cancel
                    </Button>
                }
                <Button
                    color="success"
                    onClick={onUpdate}
                    loading={isLoading}
                >
                    Update
                </Button>
            </div>
        </div>
    )
}