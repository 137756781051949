import React from 'react';
import Typography from '../Core/Typography';

const Footer: React.FC = () => {
    return (
        <footer>
            <div className='content'>
                <Typography variant='caption' textAlign='right'>© Applied Dynamic Solutions {new Date().getFullYear()} </Typography>            
            </div>
        </footer>
    );
};

export default Footer;
