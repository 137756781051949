import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import ProfilePhoto from '../../assets/images/default.png';
import { UserRole } from '../../models/User';
import Avatar from '../Core/Avatar';

import Menu, { MenuItem } from '../Core/Menu';

type Props = {
    activeItem: string;
};

const AccountActions: React.FC<Props> = ({ }) => {
    const { isLoggedIn, user } = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
    const AvatarRef = React.useRef<HTMLDivElement>(null);
    const history = useHistory();

    const handleNavigation = (route: string, state?: unknown) => {
        history.push(route, state);
    };

    return (
        <>
{/* _            {!isLoggedIn && (
                <div className="account-actions">
                    <Link to="/login">
                    <p className={`nav-item ${activeItem === "Login" ? 'active' : ''}`} role="navigation">
                        <span>Login</span>
                    </p>
                    </Link>
                    <Button
                        color="secondary"
                        onClick={() => handleNavigation('/register')}
                    >
                        SIGN UP
                    </Button>
                </div>
            )} */}
            {isLoggedIn && (
                <div className="account-actions">
                    {user && (
                        <div className="profile-section">
                            <div>
                                <p>
                                    {user?.firstName} {user?.lastName}
                                    <br />
                                    {user?.role === UserRole.Admin && <span className="user-role">ADMIN</span>}
                                </p>
                            </div>
                            <div
                                onClick={() => setProfileMenuOpen(true)}
                                tabIndex={0}
                                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && handleNavigation('/profile')
                                }
                                className="action-bar-avatar"
                                ref={AvatarRef}
                            >
                                <Avatar
                                    size="small"
                                    source={user.photo.length> 0 ? user.photo : ProfilePhoto}
                                    name={(user?.firstName + ' ' + user?.lastName) as string}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Menu open={profileMenuOpen} elementRef={AvatarRef} close={() => setProfileMenuOpen(false)} width={120}>
                <MenuItem
                    label="Profile"
                    onClick={() => {
                        setProfileMenuOpen(false);
                        handleNavigation('/profile');
                    }}
                />
                <MenuItem
                    label="Logout"
                    onClick={() => {
                        setProfileMenuOpen(false);
                        dispatch({ type: AuthActionType.LOGOUT });
                        handleNavigation('/login');
                    }}
                />
            </Menu>
        </>
    );
};

export default AccountActions;
