import Invitation from "../models/Invitation";
import instance from "./instance";

function getAllInvitations(): Promise<Invitation[]>{
    return instance.get(`invitation`)
    .then((response) => {          
        return response.data;
    });
}

function inviteUser(email: string) : Promise<string>{
    return instance.post(`invitation?email=${email}`)
    .then((response) => {                 
        return response.data;
    });
}

function assignMultipleTokens(requestId: string,tokens: string[]): Promise<string>{
    return instance.put(`invitations?requestId=${requestId}`, tokens)
    .then((response)=>{
        return response.data;
    })
}

export {getAllInvitations, inviteUser, assignMultipleTokens};