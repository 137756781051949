import React from "react"
import Modal from "../../components/Core/Modal"
import { InvitationStatus, Token } from "../../models/Invitation"
import Typography from "../../components/Core/Typography"
import "./tokenModal.scss"
import Checkbox from "../../components/Core/Checkbox"
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/chevron-down.svg';
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state"
import Button from "../../components/Core/Button"
import { InviteService } from "../../api"
import IconButton from "../../components/Core/IconButton"


type Props = {
    requestId: string,
    tokens: Token[]
    onSuccess: () => void,
    onCancel: () => void,
    isOpen: boolean

}

const TokenModal: React.FC<Props> = ({ requestId, tokens, onSuccess, onCancel, isOpen }) => {
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [assigned, setAssigned] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);


    const handleCheckboxChange = (token: string) => {
        if (assigned.includes(token)) {
            const updatedAssigned = assigned.filter((item) => item !== token);
            setAssigned(updatedAssigned);
        } else {
            const updatedAssigned = [...assigned, token];
            setAssigned(updatedAssigned);
        }
    }

    const handleClick = () => {
        document.getElementById('assignButton')?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleCopy = (token: string) => {
        const textarea = document.createElement('textarea');
        textarea.value = token;
        // Set the textarea's position to be off-screen
        textarea.style.position = 'fixed';
        textarea.style.top = '-9999px';

        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        notificationDispatch({
            type: NotificationActionType.OPEN,
            payload: {
                text: "Successfully copied to clipboard",
                status: 'success',
                autoClose: true,
            },
        });
    }

    const handleSubmit = () => {
        setIsLoading(true);
        InviteService.assignMultipleTokens(requestId, assigned)
            .then((response) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: response,
                        status: 'success',
                        autoClose: true,
                    },
                });
                onSuccess();
            }).catch((e) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: e.message,
                        status: 'error',
                        autoClose: true,
                    },
                });
            }).finally(() => {
                setAssigned([]);
                setIsLoading(false);
            });
    }
    return <>
        <Modal isOpen={isOpen} close={onCancel} size='large' title="Assign Tokens">
            <div>
                <div className="strip-detail">
                    {tokens.length > 12 && <div className="down-icon">
                        <IconButton Icon={DownIcon} onClick={handleClick} />
                    </div>}
                    <div className="legend-container">
                        <div className="legend-item">
                            <div className={`legend--error`}>
                                <div className='legend-metadata'>
                                    <Typography variant="body">
                                        Assigned
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="legend-item">
                            <div className={`legend--success`}>
                                <div className='legend-metadata'>
                                    <Typography variant="body">
                                        Unassigned
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    {tokens.map(token => (
                        <div className="detail-item-container" key={token.token}>
                            <div className="detail-item">
                                {token.status == InvitationStatus.TOKEN_GENERATED &&
                                    <Checkbox checked={assigned.includes(token.token)} onChange={() => handleCheckboxChange(token.token)} />
                                }
                                <div className={`detail-description--${token.status == InvitationStatus.TOKEN_ASSIGNED ? "error" : "success"}`}>
                                    <div className='strip-detail-description-metadata'>
                                        <Typography key={token.token} variant="body">
                                            {`${window.location.origin}/vcard/${token.token}`}
                                        </Typography>
                                    </div>
                                </div>
                                {token.status == InvitationStatus.TOKEN_GENERATED &&
                                    <CopyIcon onClick={() => handleCopy(`${window.location.origin}/vcard/${token.token}`)} style={{ cursor: "pointer" }} title="Copy to Clipboard" />
                                }
                            </div>
                        </div>

                    ))
                    }
                </div>
            </div>
            <div className="action-container" style={{ justifyContent: 'flex-end' }} id="assignButton">
                <Button
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    Assign
                </Button>
            </div>
        </Modal>
    </>
}

export default TokenModal;