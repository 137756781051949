import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { emailIsValid, passwordAreEqual, passwordIsValid } from '../../utils';
import Typography from '../../components/Core/Typography';
import InputField from '../../components/Core/InputField';
import Button from '../../components/Core/Button';
import { UserService } from '../../api';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';


const Register:React.FC=()=>{
    const { email, token } = useParams<{ email: string, token: string }>();
    const [registerBody, setRegisterBody] = React.useState({ 
                                            email:   '',
                                              password: '',
                                              confirmPassword: ''});
    const[showError, setShowError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const history = useHistory();
        
    React.useEffect(()=>{
      if(email && token){
        if(email!='create-new'){
            setRegisterBody({...registerBody, email: decodeURIComponent(email)})
            if(!emailIsValid(decodeURIComponent(email))){
                history.push('/404');
              }
        }
      }
      else{
        history.push('/404');
      }
    },[])

    const handleChange = (name: string, value: string): void => {
      setRegisterBody({ ...registerBody, [name]: value });
  };

  const handleRegister = () =>{
    setShowError(false);
    setIsLoading(true);
    const isPasswordValid =(passwordIsValid(registerBody.password) && passwordAreEqual(registerBody.password, registerBody.confirmPassword)) ;
    
    if(isPasswordValid){ 
      UserService.createUser(registerBody.email, token, registerBody.password)
        .then((response) => {
                notificationDispatch({
                    type: NotificationActionType.OPEN,
                    payload: {
                        text: response,
                        status: 'info',
                        autoClose: true,
                    },
                });
                history.push('/login');
        })
        .catch((e) => {            
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: e.message,
                    status: 'error',
                    autoClose: true,
                },
            });
        }).finally(()=> setIsLoading(false));
       
    }
    else{
        setIsLoading(false);
        setShowError(true);
    }
    
}
   
    // return (
    //     <ProfileDetailsForm user = {{...initUser, email: decodeURIComponent(email)}} onEditOrCancel={()=> history.push('/login')} registrationToken = {token}/>
        
    //     )
    return (
    <div className="login-container paper">
    <form
        onSubmit={(e) => {
            e.preventDefault();
            handleRegister();
        }}
    >
        <Typography variant="title" weight={500} color="primary">
            REGISTER
            <hr />
        </Typography>
        
        <div>
        <Typography variant="body" weight={600} color="primary">
            EMAIL
        </Typography>
        
        <InputField
            onChange={handleChange}
            name="email"
            type="email"
            value={registerBody.email}
            disabled = {email!='create-new'}
        />
        </div>
        <div>
        <Typography variant="body" weight={600} color="primary">
            PASSWORD
        </Typography>
        <InputField
            onChange={handleChange}
            name="password"
            type="password"
            error = {showError && !passwordIsValid(registerBody.password)}
            errorText='Invalid Password'
        />
        </div>
        <div>
        <Typography variant="body" weight={600} color="primary">
            CONFIRM PASSWORD
        </Typography>
        <InputField
            onChange={handleChange}
            name="confirmPassword"
            type="password"
            error = {showError && !passwordAreEqual(registerBody.password, registerBody.confirmPassword)}
            errorText='Passwords do not match'
        />
        </div>
        <div className="action-container" style = {{justifyContent: 'flex-end'}}>
            <Button
                color="primary"
                loading={isLoading}
                type="submit"
            >
                Register
            </Button>
        </div>
    </form>
</div>
);
}

export default Register;