import React from "react";
import Typography from '../../components/Core/Typography';
import Logo from '../../assets/images/logo.png';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AuthService } from "../../api";
import { NotificationActionType, NotificationContext } from "../../components/Core/Notification/state";

const VCard: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const history = useHistory();   
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;

    React.useEffect(()=>{
        getData();
    },[])

    const getData = () =>{
        AuthService.checkNFCStatus(token).then((resp)=>{
            if(resp){
                AuthService.getVCard(token).then((resp)=>{
                    const blob = new Blob([resp], { type: 'text/x-vcard' });
                    const url = URL.createObjectURL(blob);
            
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'vcard.vcf'; 
                
                    link.click();                
                    URL.revokeObjectURL(url);
                }).catch((_e)=>{
                    notificationDispatch({
                        type: NotificationActionType.OPEN,
                        payload: {
                            text: "Something went wrong. Please Try Again.",
                            status: 'error',
                            autoClose: true,
                        },
                    });
                });
            }
            else{
                history.push(`/register/create-new/${token}`)
            }
        }).catch((_error)=>{
            history.push(`/register/create-new/${token}`)
        })
    }

    return (
        <div className="page forgot-password-page">
            <section>
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <Typography variant='heading' weight={800} color="tertiary" textAlign='center'>
                    NFC CARDS
                </Typography>
                <Typography variant="subtitle" textAlign='center'>
                    Your Virtual Card will be with you shortly.
                </Typography>
                <br/>
                <Typography variant="subtitle" textAlign="center" color="info">
                    <Link to="/login"><span
                        className="text-action"
                    >
                        Login
                    </span></Link>
                </Typography>
            </section>
        </div>
    );
};

export default VCard;