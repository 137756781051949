import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import Authentication from './containers/Authentication';
import './assets/styles/main.scss';
import { NotificationContext, notificationInitialState, notificationReducer } from './components/Core/Notification/state';
import { AlertDialogContext, alertDialogInitialState, alertDialogReducer } from './components/Core/AlertDialog/state';
import Notification from './components/Core/Notification';
import AlertDialog from './components/Core/AlertDialog';
import Layout from './components/Layout';

const App: React.FC = () => {
    const [notificationState, notificationDispatch] = React.useReducer(notificationReducer, notificationInitialState);
    const [alertDialogState, alertDialogDispatch] = React.useReducer(alertDialogReducer, alertDialogInitialState);
    return (
        <main>
            <Router>
                <NotificationContext.Provider value={{ notificationState, notificationDispatch }}>
                    <AlertDialogContext.Provider value={{alertDialogState, alertDialogDispatch}}>
                        <Authentication>
                            <Layout>
                                <AppRouter />
                            </Layout>
                            <Notification />
                            <AlertDialog/>
                        </Authentication>
                    </AlertDialogContext.Provider>
                </NotificationContext.Provider>
            </Router>
        </main>
    );
};

export default App;
