import { UserRole } from "../models/User";
import { NavPosition, Route } from "./models";
import ProfilePage from '../containers/Profile';
import LandingPage from '../containers/Landing';
// import DashboardPage from '../containers/Dashboard';
import InvitationPage from '../containers/Invitation';
import RequestPage from '../containers/Request';
import ForgotPasswordPage from '../containers/ForgotPassword';
import ResetPasswordPage from '../containers/ResetPassword';
import {ReactComponent as DashboardIcon } from '../assets/icons/pie-chart.svg';
import {ReactComponent as UserIcon } from '../assets/icons/user.svg';
import {ReactComponent as InviteIcon } from '../assets/icons/plus.svg';
import {ReactComponent as RequestIcon } from '../assets/icons/save.svg';
import NotFound from "../containers/NotFound";
import Register from "../containers/Register";
import Login from "../containers/Login";
import VCard from "../containers/VCard";

const routes: Array<Route> = [   
    {
        label: 'Forgot Password',
        route:  '/forgot-password',
        roles:  [UserRole.Visitor],
        Component: ForgotPasswordPage,
        exact: true
    },
    {
        label: 'Reset Password',
        route:  '/reset-password/:token/:email',
        roles:  [UserRole.Visitor],
        Component: ResetPasswordPage,
        exact: false   
    },
    {
        label: 'Profile',
        route:  '/profile',
        roles:  [UserRole.Admin,UserRole.User],
        Component: ProfilePage,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav:true,
        Icon: UserIcon,
        exact: true
    },
    // {
    //     label: 'Dashboard',
    //     route:  '/dashboard',
    //     roles:  [UserRole.User, UserRole.Admin],
    //     Component: DashboardPage,
    //     navPosition: NavPosition.VERTICAL,
    //     showHorizontalNav: true,
    //     showVerticalNav: true,
    //     Icon: DashboardIcon,
    //     exact: true 
    // },      
    {
        label: 'Invitations',
        route:  '/invitations',
        roles:  [ UserRole.Admin],
        Component: InvitationPage,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav: true,
        Icon: InviteIcon,
        exact: true 
    },     
    {
        label: 'Card-Requests',
        route:  '/requests',
        roles:  [ UserRole.Admin, UserRole.User],
        Component: RequestPage,
        navPosition: NavPosition.VERTICAL,
        showHorizontalNav: true,
        showVerticalNav: true,
        Icon: RequestIcon,
        exact: true 
    },     
    {
        label: 'Not Found',
        route:  '/404',
        roles:  [UserRole.User, UserRole.Admin, UserRole.Visitor],
        Component: NotFound,
        Icon: DashboardIcon,
        exact: true 
    },  
    {
        label: 'VCard',
        route:  '/vcard/:token',
        roles:  [ UserRole.Visitor],
        Component: VCard,
        Icon: DashboardIcon,
        exact: true 
    },                
    {
        label: 'Register',
        route:  '/register/:email/:token',
        roles:  [UserRole.Visitor],
        Component: Register,        
        showHorizontalNav: true,
        exact: false 
    },               
    {
        label: 'Login',
        route:  '/login',
        roles:  [UserRole.Visitor],
        Component: Login,
        showHorizontalNav: true,
        exact: true 
    },
    //keep landing route at the end for active route filtering
    {
        label: 'Landing',
        route:  '/',
        roles:  [UserRole.Visitor],
        Component: LandingPage,
        showHorizontalNav: true,
        class: "landingPage",
        exact: true
    },
]

export default routes;