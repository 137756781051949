import React from 'react';
import './profile.scss';
import { UserViewModel } from '../../models/User';
import ProfilePhoto from '../../assets/images/default.png';
import Typography from '../../components/Core/Typography';
import {ReactComponent as WebsiteIcon} from '../../assets/icons/website.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/icons/linked.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import IconButton from '../../components/Core/IconButton';


type Props ={
    user: UserViewModel;
    onEdit: ()=>void;
}
const ProfileDetail: React.FC<Props> = ({user, onEdit}) => {

       return (
        <div className="profile-card paper">
            
            <div className="profile-card__img">
                <img src={user.photo.length>0? user.photo : ProfilePhoto} alt="profile card"/>
            </div>
            <div className='profile-card___icon'>
                <IconButton Icon = {EditIcon} onClick={onEdit}></IconButton>
            </div>

                        
            <div className="profile-card__content">
                <div className="profile-card__name">{user.firstName} {user.lastName}</div>
                <div className='profile-card__social-container'>
                    {user.website.length>0 && <a href = {user.website} target='blank'><div className='social'><WebsiteIcon/></div> </a>}                                  
                    {user.linkedin.length>0 && <a href = {user.linkedin} target='blank'><div className='social'><LinkedInIcon/></div> </a>}
                </div>
            </div>
            <div className='form-grid-half'>
                <div>
                    <Typography variant='body' weight={500}>Email Address:</Typography>
                    <span>{user.email.length>0 ? user.email : '----'}</span>
                </div>
                <div>
                    <Typography variant='body' weight={500}>Phone Number:</Typography>
                    <span>{user.phoneNumber.length>0 ? user.phoneNumber : '----'}</span>
                </div>
            </div>
            <br/>
            <div className='form-grid-half'>
                <div>
                    <Typography variant='body' weight={500}>Mobile:</Typography>
                    <span>{user.mobile.length>0 ? user.mobile : '----'}</span>
                </div>
                <div>
                    <Typography variant='body' weight={500}>Address:</Typography>
                    <span>{user.address.length>0 ? `${user.address}, ${user.city}, ${user.state}, ${user.zipCode}, ${user.country}` : '----'}</span>
                </div>
            </div>
            <br/>
            <div className='form-grid-half'>
                <div>
                    <Typography variant='body' weight={500}>Company:</Typography>
                    <span>{user.company.length>0 ? user.company : '----'}</span>
                </div>
                <div>
                    <Typography variant='body' weight={500}>Title:</Typography>
                    <span>{user.title.length > 0 ? user.title : '----'}</span>
                </div>
            </div>
            <br/>
            <div className='form-grid-half'>
                <div>
                    <Typography variant='body' weight={500}>Notes:</Typography>
                    <span>{user.note.length>0 ? user.note: '-----'}</span>
                </div>
            </div>

        </div>
       );
        

};

export default ProfileDetail;
